import React from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';

const HeaderStyle = styled.header`
`;

export default function Header(){
  return <HeaderStyle id="header">
    <Navigation />
  </HeaderStyle>;
}
