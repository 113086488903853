import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { media } from './breakpoints';
import './fonts.scss';

export const colors = {
  silver: '#eeeeee',
  gray: '#303133',
  orange: '#ff590b',
  blue: '#399bff',
} as const;

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  #gatsby-focus-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > #content{
      flex-grow: 1;
    }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  a {
    color: #00B7FF;
  }
  
  img {
    vertical-align: middle;
  }
  
  label {
    display: block;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  
  h1 {
    font-size: 48px;
    font-weight: 400;
    color: white;
  }
  
  h2 {
    font-size: 34px;
    color: #2C2E30;
    line-height: 46px;
  }
  
  h3 {
    font-size: 24px;
  }

  svg{
    //iOS Safari tends to shrink SVG on us while other browsers don't
    flex-shrink: 0;
  }
`;
